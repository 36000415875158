













































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand } from "@/store/models/DemandAndSupply";
import { CropVarietyStore, CropStore, DemandSupplyStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Supply List",
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;

  get dataList() {
    return DemandSupplyStore.Supplies;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    return this.nepaliUnicodeNumber(index + 1);
  }

  nepaliDate(date: string) {
    return nepaliDate(date, this.$i18n.locale);
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  toogleExpand(row: any) {
    let $table = this.$refs.dataTable as any;
    $table.toggleRowExpansion(row);
  }

  sold(supply: any, distribution: any, supplyIndex: number, distributionIndex: number) {
    let company = distribution.demandcompany;
    let allocatedquantity = distribution.allocatedquantity;
    let quantity = distribution.quantity;
    let id = distribution.id;
    let group = supply.croptypename;
    let variety = supply.cropvarietyname;
    let seedtype = supply.seedtypename;
    this.$confirm(
      "This will mark the demand of " +
        company +
        " for " +
        String(allocatedquantity) +
        " kg of " +
        group +
        "'s " +
        variety +
        "'s " +
        seedtype +
        " seed as sold" +
        // " with " +
        // String(quantity) +
        // " kg" +
        ". This cannot be changed later.",
      "Confirm?",
      {
        confirmButtonText: "Confirm",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        showClose: false,
        type: "warning"
      }
    )
      .then(async () => {
        console.log("Mark as sold in background");
        this.dataList[supplyIndex].distributionlist[distributionIndex].sold = true;
        console.log([this.dataList[supplyIndex].distributionlist[distributionIndex]]);
        DemandSupplyStore.distributionsSold([
          this.dataList[supplyIndex].distributionlist[distributionIndex]
        ]);
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete cancelled"
        });
      });
  }

  private async created() {
    this.language = this.$i18n.locale;
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    this.clusterId = Number(this.$route.params.clusterId);
    this.loading = true;
    await DemandSupplyStore.getSupplyByClusterId(this.clusterId);
    this.loading = false;
  }
}
