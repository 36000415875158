





























































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Preferences } from "@/store/models/DemandAndSupply";
import { CropVarietyStore, CropStore, UserStore, DemandSupplyStore } from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";

@Component({
  components: {
    DynamicField
  },
  metaInfo: {
    title: "Preferences",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Organization User Preferences",
        vmid: "companyPreferences"
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  cropvariety = {} as CropVariety;
  preference: Preferences = {} as Preferences;
  selectedIndex = -1;
  dialogFormVisible: boolean = false;
  pageNum: number = 1;
  language: string = "";
  clearButton: boolean = false;
  loading: boolean = false;
  adding: boolean = false;

  get dataList() {
    return DemandSupplyStore.Preferences;
  }

  get user() {
    return UserStore.User;
  }

  async searchText() {
    this.loading = true;
    await CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: 1
    });
    this.loading = false;
    // CropVarietyStore.getSearchedCropValue(this.searchInput);
  }

  private get crops() {
    return CropStore.CropNameList;
  }

  private get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  async add() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      if (this.user.role == "consumer") {
        this.preference.transactiontype = "demand";
      }
      this.adding = true;
      let error = false;
      let preferences = await DemandSupplyStore.postPreference(this.preference).catch(err => {
        error = true;
      });
      this.adding = false;
      if (!error) {
        preferences = preferences as Preferences;
        if (!this.clearButton) {
          this.dataList.push(preferences);
          this.$notify.success("Preference Added");
        } else {
          this.dataList.splice(this.selectedIndex, 1, preferences);
          this.$notify.success("Preference Edited");
        }
        this.clear();
      }
    }
  }

  clear() {
    this.preference = {
      breeder: false,
      foundation: false,
      certified: false,
      source: false,
      labelled: false,
      truthlabelled: false
    } as Preferences;
    this.selectedIndex = -1;
    this.clearButton = false;
  }

  private get varieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (varieties !== undefined && this.preference.croptypeid !== undefined) {
      varieties = varieties.filter(x => x.croptypeid == this.preference.croptypeid);
    } else {
      varieties = [];
    }
    return varieties;
  }

  editForm(id: number, index: number) {
    this.selectedIndex = index;
    this.preference = Object.assign({}, this.dataList.filter(x => x.id == id)[0]);
    this.clearButton = true;
  }

  onDeleteClick(index: number, id: number) {
    let cropVariety = this.dataList.filter(x => x.id == id)[0];
    if (cropVariety != undefined) {
      this.$confirm(
        "This will permanently delete the preference for crop " +
          cropVariety.croptypename +
          " of variety " +
          cropVariety.cropvarietyname +
          " and all related data. Do you want to continue?",
        "Warning",
        {
          confirmButtonText: "Delete",
          confirmButtonClass: "el-button--danger",
          cancelButtonText: "Cancel",
          showClose: false,
          type: "warning"
        }
      )
        .then(async () => {
          await DemandSupplyStore.deletePreference(id);
          this.dataList.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete cancelled"
          });
        });
    }
  }

  private async created() {
    CropStore.clearCropNameList();
    this.language = this.$i18n.locale;
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }

    // var pagenumber = CropVarietyStore.PageNumber;
    // this.cropId = Number(this.$route.query.cropId);
    // CropVarietyStore.getAllCropValue();
    // CropVarietyStore.getPaginatedCropValue({
    //   page: this.pageNum,
    //   language: this.language
    // });
    CropStore.getAllCropName();
    CropVarietyStore.getAllCropVarietiesList();
    DemandSupplyStore.getAllSeedType();

    this.loading = true;
    await DemandSupplyStore.getPreferences();
    this.loading = false;
  }

  handlePageChange(val: number) {
    this.pageNum = val;
    // CropVarietyStore.setPageNumber(val);
    CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: val,
      language: this.language
    });
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(val) }
        })
        .catch(error => {});
    }
  }
}
