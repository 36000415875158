









































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { UserStore, JWTStore } from "@/store/modules";

@Component({
  metaInfo: {
    title: "Organization",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      { name: "description", content: "Organization Panel", vmid: "companyScreen" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class Adminmenu extends Vue {
  get user() {
    return UserStore.User;
  }
}
